import React, { Suspense, useEffect } from "react";
import { ConfigProvider, Space, Button, notification, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { reset, set } from "automate-redux";
const AuthenticatedRoutes = lazyWithPreload(() => import("./AuthenticatedRoutes"));
import { loadMasters, loadStates } from "./thunks/master";
import { loadPrintSettings, loadSettings } from "./thunks/settings";
import {
  acknowledgeUpdate,
  getIsUpdateAcknowledged,
  getIsUpdateAvailable,
  getOrgDetails,
  getOrganizations,
  getProfile,
  getSelectedFcYearId,
  getSelectedOrgId,
  getUserOrgDetails,
  // loadIsUpdateAvailable,
  loadOrganizationDetails,
  loadProfile,
  loadUserOrgGroups,
  loadUserOrganizations,
  // resetUpdateAcknowledged,
  // setIsUpdateAvailabe,
  updateApp,
} from "./thunks/user-management";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { useIdleTimer } from "react-idle-timer";
import UnauthenticatedRoutes from "./UnauthenticatedRoutes";
import RequestAccess from "./pages/request-access/Index";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { differenceInDays, format } from "date-fns";
import { SuspenseFallback, addAppTab, decrementPendingRequests, incrementPendingRequests, lazyWithPreload, showReleaseNotesPopup, usePrevious } from "./utils";
import store from "./store";
import packageInfo from "../package.json"
import { userOrgRoles } from "./constants";

const App = () => {
  const dispatch = useDispatch();
  const profileLoaded = useSelector((state) => state.profileLoaded);
  const selectedOrgId = useSelector((state) => getSelectedOrgId(state));
  const previousSelectedOrgId = usePrevious(selectedOrgId);
  const selectedOrgDetails = useSelector((state) => getOrgDetails(state));
  const isUpdateAvailable = useSelector((state) => getIsUpdateAvailable(state));
  const selectedFcYearId = useSelector((state) => getSelectedFcYearId(state));
  const selectedSessionId = useSelector((state) => state.selectedSessionId);
  const screenLockPassed = useSelector((state) => state.screenLockPassed);
  const userOrgDetails = useSelector(state => getUserOrgDetails(state));
  const userOrgRole = userOrgDetails.role;
  const userOrgPermissions = userOrgDetails.permissions || {};
  const profile = useSelector(state => getProfile(state));
  const onScreenLockTimeout = () => {
    window.activeElementBeforeScreenLock = document.activeElement;
    dispatch(set("screenLockPassed", false))
  };
  const orgs = useSelector((state) => getOrganizations(state));
  const org = orgs.find((obj) => obj.id === selectedOrgId);
  const requireAccessApproval =
    org?.requires_approval && new Date().toISOString() >= (org.expires_at || "");

  const screenLockTimer = useIdleTimer({
    onIdle: onScreenLockTimeout,
    timeout: 5 * 60 * 1000,
    startOnMount: false,
    startManually: true,
  });

  // const onSWUpdate = () => {
  //   resetUpdateAcknowledged();
  //   dispatch(setIsUpdateAvailabe(true));
  // };

  useEffect(() => {
    try {
      // serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
      // dispatch(loadIsUpdateAvailable());
      dispatch(loadStates());
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  useEffect(() => {
    const handleEffect = async () => {
      if (selectedSessionId) {
        try {
          incrementPendingRequests();
          await Promise.all([dispatch(loadProfile()), dispatch(loadUserOrganizations()), dispatch(loadUserOrgGroups())]);
        } finally {
          decrementPendingRequests();
        }
      }
    }
    handleEffect();
  }, [selectedSessionId]);

  useEffect(() => {
    if (!screenLockPassed) {
      screenLockTimer.pause();
    } else {
      screenLockTimer.start();
      const previousVersion = localStorage.getItem("version");
      const versionChanged = previousVersion && previousVersion !== packageInfo.version;
      if (localStorage.getItem("promptReleaseNotes") || versionChanged) {
        showReleaseNotesPopup();
      }
      localStorage.setItem("version", packageInfo.version);
      if (window.activeElementBeforeScreenLock) {
        window.activeElementBeforeScreenLock?.focus?.()
      }
    }
  }, [screenLockPassed]);

  useEffect(() => {
    if (selectedOrgDetails && screenLockPassed && selectedOrgDetails.status === "FREEZE") {
      const dueDate = selectedOrgDetails.due_date ? format(new Date(selectedOrgDetails.due_date), "dd MMM yyyy") : "";
      const isFreezed = format(new Date(), "yyyy-MM-dd") > selectedOrgDetails.due_date;

      const modalRef = { current: null };
      if (isFreezed) {
        const title = `Your database is freezed due to non payment of cloud subscription. 
        Please clear your due payment to renew cloud subscription. 
        क्लाउड सब्सक्रिप्शन का भुगतान न करने के कारण आपका डेटाबेस बंद हो गया है। 
        क्लाउड सब्सक्रिप्शन पुनः आरंभ करने के लिए, कृपया बकाया पेमेंट जल्द से जल्द कीजिये ।`
        modalRef.current = Modal.confirm({
          onOk: () => modalRef.current.destroy(),
          width: 600,
          icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
          closable: false,
          okText: "Okay",
          okButtonProps: { size: "large", block: true },
          cancelButtonProps: { style: { visibility: "hidden" } },
          title: <span style={{ color: "red", fontSize: 32 }}>{title}</span>
        });
      } else {
        const currentTime = new Date().getTime();
        const lastReminderTime = Number(localStorage.getItem("lastPaymentReminderTimeMs") || "0");
        const hoursSinceLastReminder = Math.floor((currentTime - lastReminderTime) / (1000 * 60 * 60));
        if (hoursSinceLastReminder >= 4) {
          localStorage.setItem("lastPaymentReminderTimeMs", currentTime);
          modalRef.current = Modal.confirm({
            onOk: () => modalRef.current.destroy(),
            closable: false,
            okText: "Okay",
            icon: null,
            cancelButtonProps: { style: { visibility: "hidden" } },
            title: <div style={{ fontWeight: "bold", color: "#d9363e" }}>Payment Reminder</div>,
            styles: {
              body: { background: "#fff2f0" },
              content: { background: "#fff2f0" },
            },
            content: `Please clear due payment of software${dueDate ? ` by ${dueDate}` : ""} to continue using software without any interruption. 
सॉफ़्टवेयर का उपयोग बिना किसी रुकावट के जारी रखने के लिए, कृपया सॉफ़्टवेयर का बकाया भुगतान ${dueDate} के पहले कर दीजिय ।`
          });
        }
      }
    }
  }, [selectedOrgDetails, screenLockPassed]);

  useEffect(() => {
    if (
      selectedOrgDetails?.id &&
      screenLockPassed &&
      profile.role !== "super-user" &&
      (userOrgRole === userOrgRoles.OWNER || userOrgPermissions.settings?.update)
    ) {
      const daysSinceBackup = differenceInDays(new Date(), new Date(selectedOrgDetails.backup_at || selectedOrgDetails.created_at));
      if (daysSinceBackup >= 30 && format(new Date(), "yyyy-MM") > '2024-07') {
        const modalRef = { current: null };
        modalRef.current = Modal.confirm({
          onOk: () => {
            modalRef.current.destroy();
            addAppTab("/settings/backup", "Backup")
          },
          icon: <ExclamationCircleOutlined />,
          closable: false,
          okText: "Go to Backup",
          title: "Backup Reminder",
          content: (
            <div>
              {selectedOrgDetails.backup_at ? (
                <p>Last Backup: {format(new Date(selectedOrgDetails.backup_at), "dd-MM-yyyy h:m a")}</p>
              ) : (
                <p>You have not downloaded any backup yet. Please download backup to ensure best data safety.</p>
              )}
            </div>
          )
        });
      }
    }
  }, [selectedOrgDetails?.id, screenLockPassed])

  useEffect(() => {
    if (isUpdateAvailable) {
      const updateAcknowledged = getIsUpdateAcknowledged();
      if (!updateAcknowledged) {
        // Trigger update notification
        const key = "AppUpdateAvailable";
        const btn = (
          <Space>
            <Button type="primary" onClick={updateApp}>
              Update Now
            </Button>
          </Space>
        );
        notification.open({
          type: "info",
          placement: "bottomRight",
          duration: 0,
          message: "Update available! 🎁",
          btn,
          key,
          onClose: acknowledgeUpdate,
        });
      }
    }
  }, [isUpdateAvailable]);

  useEffect(() => {
    const handleEffect = async () => {
      if (selectedOrgId && !requireAccessApproval) {
        try {
          incrementPendingRequests();
          dispatch(reset("masters"));

          const promises = [
            dispatch(
              loadMasters([
                "HAGM",
                "AGM",
                "ACM",
                "ITG",
                "ITM",
                "ITS",
                "JOB",
                "PACKING",
                "UNIT",
                "HSN",
                "TAX",
                "BANK",
                "PGM",
                "SALESPERSON",
                "CONTACT",
                "FIRM",
                "REMARK",
                "SUBDESIGN",
                "RATE_CATEGORY"
              ])
            ),
            dispatch(loadSettings()),
            dispatch(loadPrintSettings()),
            dispatch(loadOrganizationDetails()),
          ];

          await Promise.all(promises);
        } finally {
          decrementPendingRequests();
        }
      }
    }
    handleEffect();
  }, [selectedOrgId, requireAccessApproval]);

  useEffect(() => {
    dispatch(reset("transactions"));
    dispatch(reset("openingTransactions"));
    // Reload accounts on changing fc year with same org to refresh account balances
    if (selectedFcYearId) {
      setTimeout(() => {
        const selectedOrgId = getSelectedOrgId(store.getState());
        if (previousSelectedOrgId && selectedOrgId === previousSelectedOrgId) {
          dispatch(reset("masters.ACM"));
          if (selectedFcYearId) {
            dispatch(loadMasters(["ACM"]));
          }
        }
      }, 10);
    }
  }, [selectedFcYearId]);

  useEffect(() => {
    AuthenticatedRoutes.preload();
  }, []);

  return (
    <ConfigProvider form={{ validateMessages: { required: "Required field" } }}
      theme={{
        token: {
          colorPrimary: "#0092e0",
          colorText: "rgba(0,0,0,0.87)",
          fontSize: 14,
          fontFamily: "'Roboto', sans-serif",
          colorBorder: "#aaa",
          borderRadius: 2,
          colorLink: "#0092e0",
        },
        components: {
          Form: {
            itemMarginBottom: 8,
          },
          Input: {
            colorBgBase: "transparent",
            paddingContentHorizontal: 8,
            paddingContentVertical: 0,
          },
          Select: {
            colorBgBase: "transparent",
          },
          Table: {
            headerBg: "#eee",
            rowSelectedBg: "#FFEB3B",
            rowHoverBg: "#EEE",
            paddingContentVertical: 8,
            borderColor: "#999"
          },
          Tag: {
            lineHeight: 32,
            fontSize: 14,
          }
        }
      }}>
      <div id="page-loader">
        <div className="loader"></div>
      </div>
      {!profileLoaded ? (
        <div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
          Loading profile...
        </div>
      ) : (
        <Suspense fallback={SuspenseFallback}>
          <AuthenticatedRoutes />
        </Suspense>
      )}
      {!screenLockPassed && (
        <div style={{ position: "fixed", top: 0, left: 0, bottom: 0, right: 0, zIndex: 1400 }}>
          <UnauthenticatedRoutes />
        </div>
      )}
      {requireAccessApproval && (
        <div style={{ position: "fixed", top: 0, left: 0, bottom: 0, right: 0, zIndex: 1400 }}>
          <RequestAccess />
        </div>
      )}
    </ConfigProvider>
  );
};

export default App;
