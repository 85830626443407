export const trnTypes = {
  SALES: "SAL",
  SALES_ORDER: "SLO",
  SALES_CHALLAN: "SLC",
  SALES_RETURN: "RSL",
  EXCESS_SALES_RETURN: "XRSL",
  SALES_CREDIT_NOTE: "SCN",
  SALES_DEBIT_NOTE: "SDN",
  QUICK_SALES: "QSAL",
  PURCHASE: "PUR",
  GENERAL_PURCHASE: "GPUR",
  PURCHASE_ORDER: "PUO",
  PURCHASE_CHALLAN: "PRC",
  PURCHASE_RETURN: "RPU",
  PURCHASE_CREDIT_NOTE: "PCN",
  PURCHASE_DEBIT_NOTE: "PDN",
  QUICK_PURCHASE: "QPUR",
  RECEIPT_ENTRY: "RCP",
  PAYMENT_ENTRY: "PYM",
  JOURNAL_ENTRY: "JRN",
  CONTRA_ENTRY: "CTR",
  MILL_ISSUE: "GIS",
  MILL_RECEIVE: "PRO",
  CUTTING: "CIS",
  JOB_WORK_ISSUE: "JIS",
  JOB_WORK_RECEIVE_CHALLAN: "JRC",
  JOB_WORK_RECEIVE: "PJW",
  JOB_WORK_INWARD: "JIN",
  JOB_WORK_OUTWARD: "JOU",
  STOCK_INWARD_ENTRY: "STI",
  STOCK_MOVEMENT_ENTRY: "STM",
  AGENCY_PURCHASE: "APUR",
  AGENCY_PURCHASE_ORDER: "APUO",
  AGENCY_PURCHASE_RETURN: "ARPU",
  AGENCY_PAYMENT: "APYM",
  AGENCY_DISPATCH_LETTER: "ADSL",
};

export const trnTypeNames = [
  { id: trnTypes.SALES, name: "Sales" },
  { id: trnTypes.SALES_ORDER, name: "Sales Order" },
  { id: trnTypes.SALES_CHALLAN, name: "Sales Challan" },
  { id: trnTypes.SALES_RETURN, name: "Sales Return" },
  { id: trnTypes.EXCESS_SALES_RETURN, name: "Excess RG" },
  { id: trnTypes.PURCHASE, name: "Purchase" },
  { id: trnTypes.PURCHASE_ORDER, name: "Purchase Order" },
  { id: trnTypes.PURCHASE_CHALLAN, name: "Purchase Challan" },
  { id: trnTypes.PURCHASE_RETURN, name: "Purchase Return" },
  { id: trnTypes.SALES_CREDIT_NOTE, name: "Sales Credit Note" },
  { id: trnTypes.SALES_DEBIT_NOTE, name: "Sales Debit Note" },
  { id: trnTypes.PURCHASE_CREDIT_NOTE, name: "Purchase Credit Note" },
  { id: trnTypes.PURCHASE_DEBIT_NOTE, name: "Purchase Debit Note" },
  { id: trnTypes.GENERAL_PURCHASE, name: "General Purchase" },
  { id: trnTypes.QUICK_SALES, name: "Quick Sales" },
  { id: trnTypes.QUICK_PURCHASE, name: "Quick Purchase" },
  { id: trnTypes.RECEIPT_ENTRY, name: "Receipt Entry" },
  { id: trnTypes.PAYMENT_ENTRY, name: "Payment Entry" },
  { id: trnTypes.JOURNAL_ENTRY, name: "Journal Entry" },
  { id: trnTypes.CONTRA_ENTRY, name: "Contra Entry" },
  { id: trnTypes.MILL_ISSUE, name: "Mill Issue" },
  { id: trnTypes.MILL_RECEIVE, name: "Mill Receive" },
  { id: trnTypes.CUTTING, name: "Cutting" },
  { id: trnTypes.JOB_WORK_ISSUE, name: "Job Issue" },
  { id: trnTypes.JOB_WORK_RECEIVE_CHALLAN, name: "Job Receive Challan" },
  { id: trnTypes.JOB_WORK_INWARD, name: "Job Inward" },
  { id: trnTypes.JOB_WORK_OUTWARD, name: "Job Outward" },
  { id: trnTypes.JOB_WORK_RECEIVE, name: "Job Receive Bill" },
  { id: trnTypes.STOCK_INWARD_ENTRY, name: "Stock Inward" },
  { id: trnTypes.STOCK_MOVEMENT_ENTRY, name: "Stock Movement" },
  { id: trnTypes.AGENCY_PURCHASE, name: "Agency Purchase" },
  { id: trnTypes.AGENCY_PURCHASE_ORDER, name: "Agency Purchase Order" },
  { id: trnTypes.AGENCY_PURCHASE_RETURN, name: "Agency Purchase Return" },
  { id: trnTypes.AGENCY_PAYMENT, name: "Agency Payment" },
  { id: trnTypes.AGENCY_DISPATCH_LETTER, name: "Agency Dispatch Letter" },
];

export const invoiceTypes = [
  { id: "TAX", name: "Tax Invoice" },
  { id: "NOTAX", name: "No Tax" },
  { id: "DEEMED EXPORT", name: "Deemed Export" },
];

export const gstNatures = [
  { id: "Regular", name: "Regular" },
  { id: "Composition", name: "Composition" },
  { id: "EXPORTER", name: "Exporter" },
  { id: "SEZ Unit", name: "SEZ Unit" },
  { id: "UNREGISTERED", name: "Unregistered" },
];

export const getBackendUrl = () =>
  process.env.NODE_ENV === "production" ? "/api" : "http://localhost:8000";

export const getDefaultLotSize = () => 12;

export const unitQuantityCodes = [
  "BAG-BAGS",
  "BAL-BALE",
  "BDL-BUNDLES",
  "BKL-BUCKLES",
  "BOU-BILLION OF UNITS",
  "BOX-BOX",
  "BTL-BOTTLES",
  "BUN-BUNCHES",
  "CAN-CANS",
  "CBM-CUBIC METERS",
  "CCM-CUBIC CENTIMETERS",
  "CMS-CENTIMETERS",
  "CTN-CARTONS",
  "DOZ-DOZENS",
  "DRM-DRUMS",
  "GGK-GREAT GROSS",
  "GMS-GRAMMES",
  "GRS-GROSS",
  "GYD-GROSS YARDS",
  "KGS-KILOGRAMS",
  "KLR-KILOLITRE",
  "KME-KILOMETRE",
  "LTR-LITRES",
  "MLT-MILILITRE",
  "MTR-METERS",
  "MTS-METRIC TON",
  "NOS-NUMBERS",
  "PAC-PACKS",
  "PCS-PIECES",
  "PRS-PAIRS",
  "QTL-QUINTAL",
  "ROL-ROLLS",
  "SET-SETS",
  "SQF-SQUARE FEET",
  "SQM-SQUARE METERS",
  "SQY-SQUARE YARDS",
  "TBS-TABLETS",
  "TGM-TEN GROSS",
  "THD-THOUSANDS",
  "TON-TONNES",
  "TUB-TUBES",
  "UGS-US GALLONS",
  "UNT-UNITS",
  "YDS-YARDS",
  "OTH-OTHERS",
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const quarters = [
  "January - March",
  "April - June",
  "July - September",
  "October - December",
];

export const companyTypes = [
  "Sole Proprietorship",
  "Partnership",
  "Limited Liability Partnership",
  "Private Limited",
];

export const userOrgRoles = {
  OWNER: "owner",
  USER: "user",
};

export const fontFamilies = [
  { label: "Poppins", value: "Poppins" },
  { label: "Roboto", value: "Roboto" },
  { label: "Open Sans", value: "Open Sans" },
  { label: "Arial", value: "Arial" },
  { label: "Times New Roman", value: "Times New Roman" },
  { label: "Helvetica", value: "Helvetica" },
  { label: "Verdana", value: "Verdana" },
]

export const industryTypes = [
  { label: "Textile", value: "Textile" },
  { label: "Jobwork", value: "Jobwork" },
  { label: "Agency", value: "Agency" },
  { label: "Aadat", value: "Aadat" },
  { label: "Standard", value: "Standard" },
]