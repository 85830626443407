import React, { Suspense, useEffect, useRef } from "react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { addDays, endOfMonth, format, startOfMonth, startOfYesterday, subDays } from "date-fns";
import { axios } from "./client";
import { months, quarters, userOrgRoles } from "./constants";
import { saveAs } from "file-saver";
import printJS from "print-js";
import store from "./store";
import { createMemoryHistory } from "history";
import { nanoid } from "nanoid";
import { set } from "automate-redux";
import Mousetrap from "mousetrap";
import { getOrgSettings } from "./thunks/user-management";
import packageInfo from "../package.json";

export const getValue = (obj, key) => {
  if (!key.includes(".")) {
    return obj?.[key];
  }

  const keys = key.split(".");
  let value = obj;
  keys.forEach((k) => {
    value = value?.[k];
  });
  return value;
};

export const setValue = (obj, key, value) => {
  if (!key.includes(".")) {
    if (obj) {
      obj[key] = value;
    }
    return
  }

  const keys = key.split(".");
  let newObj = obj[keys[0]];
  if (!newObj) {
    newObj = {}
    obj[keys[0]] = newObj;
  }
  setValue(newObj, keys.slice(1).join("."), value);
};

export const notify = ({ type, title, message, help, details, duration = 2 }) => {
  const detailsStr = details ? JSON.stringify(details, null, 3) : "";
  let notifications = window.document.getElementById("notifications");
  if (!notifications) {
    notifications = document.createElement("div");
    notifications.id = "notifications";
    window.document.body.appendChild(notifications);
  }
  const notificationId = nanoid();
  const notification = document.createElement("div");
  notification.className = "toast-container"
  notification.id = "toast-container-" + notificationId;
  const removeNotification = () => {
    notification.classList.add("hide");
    notification.ontransitionend = () => notification.remove();
  }
  const icons = {
    success: `<span class="material-icons" style="font-size: 24px; margin-right: 16px">check_circle</span>`,
    error: `<span class="material-icons" style="font-size: 24px; margin-right: 16px">error</span>`,
    warning: `<span class="material-icons" style="font-size: 24px; margin-right: 16px">warning</span>`
  }
  notification.innerHTML = `
  <div class="toast ${type}" id="toast-${notificationId}">
    <div style="display: flex">
      ${icons[type]}
      <div style="flex: 1">
        <div style="font-size: 16px;">${title}</div>
        <div>${message}</div>
        ${help ? `<div style="margin-top: 4px; opacity: 0.54">${help}</div>` : ''}
      </div>
      <span
        id="toast-remove-${notificationId}"
        class="material-icons-outlined" 
        style="font-size: 16px; margin-left: 16px; cursor: pointer;" 
      >
        close
      </span>
    </div>
    ${details ? `<div
      style="
       font-size: 13px;
       margin-top: 8px;
       background: white;
       padding: 4px;
       color: var(--text-color-secondary); 
       overflow: auto; 
       max-height: 600px;"
     >
      ${detailsStr}
    </div>`: ''}
    ${duration ? `<div class="progress" style="animation: progress ${duration}s linear forwards"></div>` : ''}
  </div>`;
  notifications.appendChild(notification);
  const notificationHeight = notification.clientHeight;
  notification.style.height = notificationHeight + "px";
  document.getElementById(`toast-remove-${notificationId}`).addEventListener("click", removeNotification)
  if (duration) {
    setTimeout(removeNotification, duration * 1000);
  }
}

export const notifyError = (message, help, details, duration) => {
  if (duration == null && (details || message?.length > 100 || help?.length > 100)) {
    duration = 0
  }
  if (duration == null) {
    duration = 5;
  }
  notify({ type: "error", title: "Error", message, help, details, duration })
};
export const notifySuccess = (message, duration) => notify({ type: "success", title: "Success", message, duration });
export const notifyWarning = (message, duration) => notify({ type: "warning", title: "Warning", message, duration });

export const capitalizeFirstLetter = (string) => string?.charAt(0).toUpperCase() + string?.slice(1);

export const splitArray = (arr = [], size, evenArraySizes = false) => {
  const arrLength = arr.length;
  const totalResultArrays = Math.ceil(arrLength / size);
  const result = [];
  if (!evenArraySizes) {
    for (let i = 0; i < totalResultArrays; i++) {
      result.push(arr.slice(i * size, (i + 1) * size));
    }
    return result;
  }

  const newSize = Math.floor(arr.length / totalResultArrays);
  let elementsDistributed = 0;
  for (let i = 0; i < totalResultArrays; i++) {
    const noOfPendingArrays = totalResultArrays - i;
    const noOfPendingElements = arr.slice(elementsDistributed).length;
    const arrSize = noOfPendingElements > noOfPendingArrays * newSize ? newSize + 1 : newSize;
    result.push(arr.slice(elementsDistributed, elementsDistributed + arrSize));
    elementsDistributed += arrSize;
  }
  return result;
};

export function escapeFormList(formId, listId) {
  if (!formId || !listId) {
    return;
  }
  const formElements = document.getElementById(formId)?.elements || {};
  let listStarted = false;
  const nextEl = Object.entries(formElements).filter(([_, el]) => el.tabIndex >= 0 && el.type !== "file").sort((a, b) => {
    if (a[1].tabIndex !== b[1].tabIndex) {
      return a[1].tabIndex < b[1].tabIndex ? -1 : 1;
    }
    return 0;
  }).find(([i, el]) => {
    const indexNo = Number(i);
    if (el.id.startsWith(listId)) {
      listStarted = true;
    }

    return !Number.isNaN(indexNo) && el.tabIndex >= 0 && listStarted && !el.id.startsWith(listId);
  });
  if (nextEl) {
    setTimeout(() => nextEl[1].focus?.(), 50);
  }
}

export function handleKeyboardEvent(event) {
  if (["INPUT", "TEXTAREA"].includes(event.target.nodeName)) {
    const { form } = event.target;
    if (form) {
      const formElementsIdMap = {};
      const formElements = Object.values(form.elements)
        .filter((el) => {
          if (!el.id) {
            return true;
          }
          const occuredPreviously = formElementsIdMap[el.id];
          formElementsIdMap[el.id] = true;
          if (!occuredPreviously) {
            return true;
          }
        })
        .filter((el) => el.tabIndex >= 0 && el.type !== "file")
        .sort((a, b) => {
          if (a.tabIndex !== b.tabIndex) {
            return a.tabIndex < b.tabIndex ? -1 : 1;
          }
          const formIndexA = Array.prototype.indexOf.call(form, a);
          const formIndexB = Array.prototype.indexOf.call(form, b);
          return formIndexA < formIndexB ? -1 : 1;
        });
      const index = formElements.findIndex((el) => el === event.target);
      let nextEl = null;
      if (event.key === "Enter" && !event.shiftKey) {
        if (event.target.tabIndex === -1) {
          const allFormElements = Object.values(form.elements);
          const index = allFormElements.findIndex((el) => el === event.target);
          nextEl = allFormElements.find((obj, i) => i > index && obj.tabIndex > -1);
        } else {
          nextEl = formElements[index + 1];
        }
      }
      if (
        event.key === "0" &&
        /_\d+_/.test(event.target.id) &&
        ((event.target.className.includes("select") && !event.target.value) ||
          event.target.className.includes("checkbox") ||
          (event.target.className.includes("escape") && !event.target.value))
      ) {
        const tablePrefix = event.target.id.split(/_\d+_/)[0];
        nextEl = formElements.find((el, i) => {
          return i > index && !el.id.startsWith(tablePrefix)
        });
      }

      if (event.key === "ArrowUp" && !event.target.className.includes("select")) {
        nextEl = formElements[index - 1];
      }

      if (nextEl) {
        nextEl.focus();
        event.preventDefault();
      }
    }
  }
}

export const handleNextKeyTableField = (
  event,
  form,
  listId,
  index,
  addRowFunc,
  allowAddRow,
  handleSubmit
) => {
  const tableData = form.getFieldValue(listId);
  const listIdStr = Array.isArray(listId) ? listId.join("_") : listId;
  const totalFields = tableData.length;
  const isLastRow = index + 1 === totalFields;
  const formDOM = event.target.form;
  if (formDOM) {
    const formIndex = Array.prototype.indexOf.call(formDOM, event.target);
    const nextElArr = Object.entries(formDOM.elements).find(([i, el]) => {
      const indexNo = Number(i);
      return !Number.isNaN(indexNo) && indexNo > formIndex && el.tabIndex >= 0;
    });
    const nextEl = nextElArr && nextElArr[1];
    const isLastField = !(nextEl && nextEl.id.startsWith(listIdStr));
    if (isLastRow && allowAddRow && addRowFunc) {
      addRowFunc({});
    }
    if (!allowAddRow && isLastField) {
      handleSubmit();
      event.stopPropagation();
    }
  }
};

export const getAvatarText = (name) => (name ? name.charAt(0) : "");
export const generateNextVoucherNo = (voucherNo) => {
  if (!voucherNo) {
    voucherNo = 0;
  }
  return String(voucherNo + 1);
};

export const uploadFile = async (url, file, bodyData = {}, onUploadProgress) => {
  const formData = new FormData();
  Object.entries(bodyData).forEach(([key, value]) => {
    formData.append(key, value);
  });
  formData.append("file", file);
  const handleUploadProgress = (event) => {
    if (onUploadProgress) {
      const progress = Math.round((100 * event.loaded) / event.total);
      onUploadProgress(progress);
    }
  };
  const { status, data } = await axios.post(url, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: handleUploadProgress,
  });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const uploadFiles = async (url, files, bodyData = {}, onUploadProgress) => {
  const formData = new FormData();
  Object.entries(bodyData).forEach(([key, value]) => {
    formData.append(key, value);
  });
  files?.forEach(file => formData.append("files", file));
  const handleUploadProgress = (event) => {
    if (onUploadProgress) {
      const progress = Math.round((100 * event.loaded) / event.total);
      onUploadProgress(progress);
    }
  };
  const { status, data } = await axios.post(url, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: handleUploadProgress,
  });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const mobileValidator = (_, value) => {
  if (!value) {
    return Promise.resolve();
  }
  if (value.length !== 10) {
    return Promise.reject("Mobile number must be of 10 digits");
  }
  if (Number.isNaN(Number(value))) {
    return Promise.reject("Mobile number must contain numbers only");
  }
  return Promise.resolve();
};

export const panValidator = (_, value) => {
  if (!value) {
    return Promise.resolve();
  }
  const regexTest = /[A-Z]{5}\d{4}[A-Z]{1}/.test(value);
  if (!regexTest) {
    return Promise.reject("Invalid PAN No");
  }
  return Promise.resolve();
};

export const gstinValidator = (_, value) => {
  if (!value) {
    return Promise.resolve();
  }
  if (value.length !== 15) {
    return Promise.reject("Invalid GST No");
  }
  const regexTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(value);
  if (!regexTest) {
    return Promise.reject("Invalid GST No");
  }
  return Promise.resolve();
};

export const nonZeroNumberValidator = (_, value) => {
  if (value === 0) {
    return Promise.reject("Zero is not allowed");
  }
  if (!value) {
    return Promise.reject("Required field");
  }
  return Promise.resolve();
};

export const formatCompanyUserRole = (role) => {
  switch (role) {
    case userOrgRoles.OWNER:
      return "Owner";
    case userOrgRoles.USER:
      return "User";
    default:
      return role;
  }
};

export const showYesNoConfimationModal = ({ onOk,
  onCancel,
  activeElement,
  nextActiveElement,
  title,
  content,
  autoFocusButton = "cancel",
  okText = "Yes (y)",
  cancelText = "No (n)",
  ...restProps
}) => {
  if (!activeElement) {
    activeElement = document.activeElement;
  }
  if (content === undefined) {
    content = "Do you want to continue?";
  }
  setTimeout(() => {
    const modalRef = { current: null };
    const handleCancel = () => {
      modalRef.current?.destroy();
      onCancel?.();
      setTimeout(() => activeElement?.focus?.(), 100);
    }
    const handleOk = () => {
      modalRef.current?.destroy();
      setTimeout(() => nextActiveElement?.focus?.(), 100);
      onOk?.();
    }
    const keyListener = (e) => {
      switch (e.key) {
        case "n":
        case "N":
          handleCancel();
          break;
        case "y":
        case "Y":
          handleOk();
          break;
        case "ArrowRight": {
          const list = document.querySelectorAll('.ant-modal-confirm-btns button');
          const index = Array.from(list).indexOf(document.activeElement);
          const nextEl = list[index + 1] || list[0];
          nextEl?.focus?.();
          break
        }
        case "ArrowLeft": {
          const list = document.querySelectorAll('.ant-modal-confirm-btns button');
          const index = Array.from(list).indexOf(document.activeElement);
          const nextEl = list[index - 1] || list[list.length - 1];
          nextEl?.focus?.();
          break
        }
      }
    }
    document.addEventListener("keydown", keyListener)
    if (!nextActiveElement) {
      nextActiveElement = document.activeElement;
    }
    modalRef.current = Modal.confirm({
      onOk: () => {
        handleOk();
        return Promise.resolve()
      },
      onCancel: () => handleCancel(),
      afterClose: () => document.removeEventListener("keydown", keyListener),
      autoFocusButton,
      icon: <ExclamationCircleOutlined />,
      title,
      content,
      okText,
      cancelText,
      okButtonProps: { style: { backgroundColor: "#0092e0" } },
      ...restProps
    });
  }, 100)
}

export const alertIfDateIsHoliday = (dateStr) => {
  if (!dateStr) return dateStr;
  const holidayDay = getOrgSettings(store.getState()).holiday_day || "Sunday";
  const day = format(new Date(dateStr), "EEEE");
  if (day === holidayDay) {
    showYesNoConfimationModal({ title: `Date is holiday (${day})` })
  }
};

export const formatDate = (dateStr) => {
  if (!dateStr) return dateStr;
  const [year, month, date] = dateStr.split("-");
  if (month && date && year) {
    dateStr = `${date}-${month}-${year}`;
  }
  return dateStr;
};

export const formatNumber = (number, decimalPlaces = 2) => {
  if (number === undefined || number === null || number === "" || Number.isNaN(Number(number))) {
    return "";
  }

  return new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(number);
};

export const mergeExistingAndUpdatedRecords = (existingRecords, updatedRecords) => {
  const oldRecordsIdIndexMap = existingRecords.reduce((prev, curr, i) => {
    prev[curr.id] = i;
    return prev;
  }, {});
  const result = existingRecords.slice();
  updatedRecords.forEach((record) => {
    if (Object.prototype.hasOwnProperty.call(oldRecordsIdIndexMap, record.id)) {
      const recordIndex = oldRecordsIdIndexMap[record.id];
      result[recordIndex] = record;
    } else {
      result.push(record);
    }
  });
  return result;
};

export const getLastTimestampOfRecords = (records = []) => {
  let lastTimeStamp = "";
  records.forEach((record) => {
    if (record.created_at > lastTimeStamp) {
      lastTimeStamp = record.created_at;
    }
    if (record.updated_at > lastTimeStamp) {
      lastTimeStamp = record.updated_at;
    }
    if (record.ts > lastTimeStamp) {
      lastTimeStamp = record.ts;
    }
  });
  return lastTimeStamp;
};

export const getFromToDatesFromPeriod = (period, fcYearFromDate, fcYearToDate) => {
  switch (period.type) {
    case "Today": {
      const currentDate = format(new Date(), "yyyy-MM-dd");
      return { fromDate: currentDate, toDate: currentDate };
    }
    case "Yesterday": {
      const previousDate = format(startOfYesterday(), "yyyy-MM-dd");
      return { fromDate: previousDate, toDate: previousDate };
    }
    case "Current Month": {
      const monthStartDay = startOfMonth(new Date());
      const monthEndDay = endOfMonth(new Date());
      return {
        fromDate: format(monthStartDay, "yyyy-MM-dd"),
        toDate: format(monthEndDay, "yyyy-MM-dd"),
      };
    }
    case "Previous Month": {
      const monthStartDay = startOfMonth(new Date());
      const previousMonthEndDay = subDays(new Date(format(monthStartDay, "yyyy-MM-dd")), 1);
      const previousMonthStartDay = startOfMonth(
        new Date(format(previousMonthEndDay, "yyyy-MM-dd"))
      );
      return {
        fromDate: format(previousMonthStartDay, "yyyy-MM-dd"),
        toDate: format(previousMonthEndDay, "yyyy-MM-dd"),
      };
    }
    case "Previous Quarter": {
      const today = new Date();
      const quarter = Math.floor(today.getMonth() / 3);

      const startQuarter = new Date(today.getFullYear(), quarter * 3 - 3, 1);
      const endQuarter = new Date(startQuarter.getFullYear(), startQuarter.getMonth() + 3, 0);

      return {
        fromDate: format(startQuarter, "yyyy-MM-dd"),
        toDate: format(endQuarter, "yyyy-MM-dd"),
      };
    }
    case "All": {
      return {
        fromDate: "1970-01-01",
        toDate: fcYearToDate,
      };
    }
    case "Custom": {
      return { fromDate: period.fromDate, toDate: period.toDate };
    }

    default:
      if (months.includes(period.type)) {
        let monthIndex = months.findIndex((name) => name === period.type) + 1;
        if (monthIndex < 10) {
          monthIndex = `0${monthIndex}`;
        }
        const fcYearStartingYear = Number(fcYearFromDate.slice(0, 4));
        const fcYearEndingYear = Number(fcYearToDate.slice(0, 4));
        if (monthIndex > 3) {
          const fromDate = `${fcYearStartingYear}-${monthIndex}-01`;
          return { fromDate, toDate: format(endOfMonth(new Date(fromDate)), "yyyy-MM-dd") };
        }
        const fromDate = `${fcYearEndingYear}-${monthIndex}-01`;
        return { fromDate, toDate: format(endOfMonth(new Date(fromDate)), "yyyy-MM-dd") };
      } else if (quarters.includes(period.type)) {
        const quarterIndex = quarters.findIndex((name) => name === period.type);
        const fcYearStartingYear = Number(fcYearFromDate.slice(0, 4));
        const fcYearEndingYear = Number(fcYearToDate.slice(0, 4));
        let fromDate;
        switch (quarterIndex) {
          case 0: {
            fromDate = `${fcYearEndingYear}-01-01`;
            break;
          }
          case 1: {
            fromDate = `${fcYearStartingYear}-04-01`;
            break;
          }
          case 2: {
            fromDate = `${fcYearStartingYear}-07-01`;
            break;
          }
          case 3: {
            fromDate = `${fcYearStartingYear}-10-01`;
            break;
          }
        }
        const endDate = endOfMonth(addDays(new Date(fromDate), 65));
        return { fromDate, toDate: format(endDate, "yyyy-MM-dd") };
      }
      return { fromDate: fcYearFromDate, toDate: fcYearToDate };
  }
};

export const extractNumberFromString = (value) => {
  if (!value) {
    return 0;
  }

  return Number(value.replace(/\D/g, ""));
};

const extractFileBlobAndFileNameFromServerResponse = (serverResponse) => {
  const blobData = serverResponse.data;
  const fileName = serverResponse.headers["content-disposition"]?.split("filename=")[1];
  const fileBlob = new Blob([blobData]);
  return { fileBlob, fileName };
}
export const saveBlobFromServer = (serverResponse) => {
  const { fileBlob, fileName } = extractFileBlobAndFileNameFromServerResponse(serverResponse);
  saveAs(fileBlob, fileName);
};

const printBlobFromServer = (serverResponse) => {
  const pdfFile = new Blob([serverResponse.data], { type: "application/pdf" });
  const pdfUrl = window.URL.createObjectURL(pdfFile);
  printJS(pdfUrl);
};

export const printOrSaveBlobFromServer = (serverResponse, action, callback) => {
  if (action === "print") {
    printBlobFromServer(serverResponse);
  } else if (action === "callback") {
    const { fileBlob, fileName } = extractFileBlobAndFileNameFromServerResponse(serverResponse);
    callback(fileBlob, fileName);
  } else {
    saveBlobFromServer(serverResponse);
  }
};

export function addAppTab(url, name, state) {
  const tabs = store.getState().tabs || [];
  const newTabs = tabs.slice();
  const tabHistory = createMemoryHistory();
  const tabId = nanoid(10);
  const historyGoBack = tabHistory.goBack;
  tabHistory.goBack = () => {
    if (tabHistory.length <= 1) {
      removeAppTab(tabId);
    } else {
      historyGoBack();
    }
  }
  tabHistory.replace(url, state);
  newTabs.push({ id: tabId, title: name || `Tab ${tabs.length + 1}`, history: tabHistory });
  store.dispatch(set("tabs", newTabs));
  store.dispatch(set("activeTabId", tabId));
}

export function removeAppTab(tabId) {
  const tabs = store.getState().tabs || [];
  const newTabs = tabs.filter((tab) => tab.id !== tabId);
  const activeTabId = newTabs[newTabs.length - 1]?.id;
  store.dispatch(set("activeTabId", activeTabId));
  store.dispatch(set("tabs", newTabs));
}

export function removeActiveAppTab() {
  const activeTabId = store.getState().activeTabId;
  removeAppTab(activeTabId);
}

export function closeAllAppTabs() {
  store.dispatch(set("activeTabId", null));
  store.dispatch(set("tabs", []));
}

export const useMouseTrap = (ref, keyHandlerMap, dependencies = []) => {
  useEffect(() => {
    if (ref?.current && keyHandlerMap && Object.keys(keyHandlerMap).length > 0) {
      const mouseTrap = new Mousetrap(ref.current);
      Object.entries(keyHandlerMap).forEach(([key, handler]) => {
        mouseTrap.bind(key.split(","), handler);
      });

      return () => {
        Object.keys(keyHandlerMap).forEach((key) => {
          mouseTrap.unbind(key.split(","));
        });
      };
    }
  }, [ref.current, ...dependencies]);
};

export function moveToNextTab() {
  const state = store.getState();
  const tabs = state.tabs || [];
  const activeTabId = state.activeTabId;
  if (activeTabId && tabs.length > 1) {
    const activeTabIndex = tabs.findIndex((tab) => tab.id === activeTabId);
    const totalTabs = tabs.length;
    if (activeTabIndex + 1 < totalTabs) {
      store.dispatch(set("activeTabId", tabs[activeTabIndex + 1].id));
    } else {
      store.dispatch(set("activeTabId", tabs[0].id));
    }
  }
}

export function moveToPrevTab() {
  const state = store.getState();
  const tabs = state.tabs || [];
  const activeTabId = state.activeTabId;
  if (activeTabId && tabs.length > 1) {
    const activeTabIndex = tabs.findIndex((tab) => tab.id === activeTabId);
    if (activeTabIndex !== 0) {
      store.dispatch(set("activeTabId", tabs[activeTabIndex - 1].id));
    } else {
      store.dispatch(set("activeTabId", tabs[tabs.length - 1].id));
    }
  }
}

export const removeLastCommaAndWhiteSpaces = (str) => {
  return str?.replace(/,\s*$|\s*$/, "");
};

const bankHolidays = [
  { month: 0, day: 26 },
  { month: 7, day: 15 },
  { month: 9, day: 2 },
  { month: 11, day: 25 },
];

const getWeekDayOccurenceInMonth = (date) => {
  const daysPassedInMonth = date.getDate();
  return Math.ceil(daysPassedInMonth / 7);
};

const checkIfBankHoliday = (bankHolidays, date) => {
  const monthDay = date.getDate();
  const month = date.getMonth();
  return bankHolidays.some((obj) => obj.month === month && obj.day === monthDay);
};

export const addBankDays = (from, noOfDays) => {
  const fromDate = new Date(from);
  let resultDate = fromDate;
  let daysAdded = 0;
  while (daysAdded < noOfDays) {
    const nextDate = addDays(resultDate, 1);
    const isBankHoliday = checkIfBankHoliday(bankHolidays, nextDate);
    const weekDay = nextDate.getDay();
    const isSunday = weekDay === 0;
    const isSaturday = weekDay === 6;
    const isSecondOrFourthSaturday = isSaturday && getWeekDayOccurenceInMonth(nextDate) % 2 === 0;
    if (!isBankHoliday && !isSunday && !isSecondOrFourthSaturday) {
      daysAdded = daysAdded + 1;
    }
    resultDate = nextDate;
  }
  return format(resultDate, "yyyy-MM-dd");
};


export const incrementPendingRequests = () => {
  window.pendingRequests = (window.pendingRequests || 0) + 1;
  if (window.pendingRequests > 0) {
    window.loaderTimer = setTimeout(() => {
      if (window.pendingRequests) {
        const pageLoader = document.getElementById("page-loader");
        if (pageLoader) {
          pageLoader.style.display = "flex";
        }
      }
    }, 50);
  }
}
export const decrementPendingRequests = () => {
  window.pendingRequests = window.pendingRequests - 1;
  if (window.pendingRequests <= 0) {
    clearTimeout(window.loaderTimer);
    const pageLoader = document.getElementById("page-loader");
    if (pageLoader) {
      pageLoader.style.display = "none";
    }
  }
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}

export const SuspenseFallback = () => (
  <div style={{
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 21,
    color: "grey"
  }}>
    Loading page...
  </div>
)

export const getSuspensedComponent = (Component) => (props) => {
  return (
    <Suspense
      fallback={SuspenseFallback}>
      <Component {...props} />
    </Suspense>
  )
}

export function lazyWithPreload(factory) {
  const Component = React.lazy(factory);
  Component.preload = factory;
  return Component;
}

export const removeAntHorizontalScrollerIfNeeded = (e) => {
  if (e) {
    const rowWidth = e.getElementsByClassName("ant-table-row")[0]?.clientWidth;
    const tableWidth = e.clientWidth;
    if (rowWidth && rowWidth <= tableWidth + 4) {
      const horizontalScrollBar = e.getElementsByClassName("ant-table-tbody-virtual-scrollbar-horizontal")[0];
      if (horizontalScrollBar) {
        setTimeout(() => {
          const horizontalScrollBar = e.getElementsByClassName("ant-table-tbody-virtual-scrollbar-horizontal")[0];
          if (horizontalScrollBar) {
            horizontalScrollBar.style.height = "0px"
          }
        }, 50)
      }
    }
  }
}

export const showReleaseNotesPopup = () => {
  const promptReleaseNotes = localStorage.getItem("promptReleaseNotes")
  Modal.info({
    icon: null,
    width: 540,
    title: promptReleaseNotes ? `Software is updated to version ${packageInfo.version}! 🎁🎉` : `Version ${packageInfo.version}`,
    content: <div dangerouslySetInnerHTML={{
      __html: `
    <div style="max-height: 540px; overflow-y: auto;">
      <h4>🔴 Breaking changes 🔴</h4>
      <p>
        <span style="background: darkorange;">Reverse charge option is removed from party master</span> and added in daybook settings, 
        as per the feedback from accountants.  
      </p>
      <p>
        Example: For transport charges, you should now have 2 daybooks, one with RCM and one without RCM.
      </p>
      <h4>GSTR improvements</h4>
      <ul>
        <li>Proper effect of inelegible and RCM entries in 2B and 3B report</li>
        <li>Displayed RCM column in GSTR-2B detail matching screen</li>           
        <li>Whatsapp & email option in GSTR-2B report</li>
        <li>Payment calculation in 3B report</li>         
        <li>Option to track gst return filing status of any party</li>                      
      </ul>
      <h4>TDS improvements</h4>           
      <ul>
        <li>Option to view tds vouchers of a particular challan</li>
        <li>Data is shown separately for multiple challans of same head & period in tds summary report</li>   
        <li>Displayed TDS section code in tds summary report</li>            
        <li>Option to skip focus on TDS fields in data entry</li>
      </ul>     
      <h4>Other improvements</h4>           
      <ul>
        <li>Automatic switching of account group & side for accounts with negative balance in balance sheet</li> 
        <li>Bills reconciled in commission recon popup will be filtered out in the comm status entry report</li>
        <li>Sale/Purchase challan entry & reconciliation in bill</li> 
        <li>Option to auto select last item rate in agency software</li>                      
        <li>Lots of bug fixes and minor improvements</li>        
      </ul>    
    </div>
    `}} />
  })
  localStorage.setItem("promptReleaseNotes", "");
}