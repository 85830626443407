import axiosClient from "axios";
import qs from "qs";
import { getBackendUrl } from "./constants";
import { notifyError } from "./utils";
import store from "./store";
import { performActionsOnSessionExpired } from "./thunks/user-management";

const axiosInstance = axiosClient.create({
  baseURL: getBackendUrl(),
  headers: { credentials: "include" },
  paramsSerializer: (params) => qs.stringify(params),
});

const nonScreenLockEndpoints = [
  "/v1/getStates",
  "/v1/getLoginOtp",
  "/v1/verifyLoginOtp",
  "/v1/verifyPin",
  "/v1/getResetPinOtp",
  "/v1/resetPin",
  "/v1/registerAccount",
  "/v1/verifyAccount",
]

// Add a request interceptor
axiosInstance.interceptors.request.use((config) => {
  const screenLockPassed = store.getState().screenLockPassed;
  if (!screenLockPassed && !nonScreenLockEndpoints.includes(config.url)) {
    notifyError("Screen lock not passed");
    return;
  }
  config.headers["Session-Id"] = localStorage.getItem("selectedSessionId");
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      if (!error.config?.params?._ignoreNetworkError) {
        notifyError("Network Error", "", "", 3);
      }
      return Promise.reject();
    }

    if (error.response.data instanceof ArrayBuffer) {
      const encoder = new TextDecoder("utf-8");
      error.response.data = JSON.parse(encoder.decode(error.response.data));
    }
    const errorMsg = error.response.data?.msg;
    const errorHelp = error.response.data?.help;
    const errorDetails = error.response.data?.details;
    if (errorMsg === "Session expired") {
      let sessionId = null;
      if (error.response.config.data?.includes("sessionId")) {
        sessionId = JSON.parse(error.response.config.data)["sessionId"];
      }
      if (!sessionId) {
        sessionId = error.response.config.headers["Session-Id"];
      }
      if (!sessionId) {
        sessionId = localStorage.getItem("selectedSessionId")
      }
      store.dispatch(performActionsOnSessionExpired(sessionId));
    }

    notifyError(errorMsg, errorHelp, errorDetails);
    return Promise.reject(error);
  }
);

export const axios = axiosInstance;
